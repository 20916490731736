import { Box } from '@mui/material';
import { StompSessionProvider } from 'react-stomp-hooks';
import Header from './components/Header';
import Server from './components/Server';

export const isAbsoluteUrl = (urlString?: string) =>
  urlString?.indexOf('http://') === 0 || urlString?.indexOf('https://') === 0;

const getHostBaseUrl = () => {
  const {REACT_APP_BACKEND_URL} = process.env;
  if (REACT_APP_BACKEND_URL !== undefined) {
    //console.log("REACT_APP_BACKEND_URL found: " + REACT_APP_BACKEND_URL);  
    return REACT_APP_BACKEND_URL;
  }
  return "/heli-x-bot/v1";
};

export enum Type {
  UPDATE = "UPDATE",
  NEW = "NEW",
  DELETE = "DELETE",
}

export interface RuntimeConfig {
  baseUrl: string;
}

export const config: RuntimeConfig = {
  baseUrl: `${getHostBaseUrl()}`,
} as const;

export interface UserWS {
  type: Type,
  session: string,
  user: User
}

export interface SessionWS {
  type: Type,
  session: StateInterface,
}


export interface User {
  name: string,
  jpaName: string,
  helicopter: string,
  playerId: number
}

export interface StateInterface {
  name: string,
  private: boolean,
  place: string,
  users: User[]
}


function App() {
  const SOCKET_URL = config.baseUrl + `/ws`;

  
  return (
    <Box maxWidth={500} margin={'auto'}>
      
      <StompSessionProvider
          url={SOCKET_URL}
        
          >
        <Header/>
        <Server/>
      </StompSessionProvider>
    </Box>
  );
}

export default App;
